@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

:root {
  --font-color-white: #f3f3f3;
  --color-neutral-gray: #222222;
  --color-black: #030303;
  --color-emerald: #7ce3cb;
  --toastify-toast-width: 484px;
  --focus-outline: 1px solid rgba(243, 227, 122, 1);
}

img{
  image-rendering: -webkit-crisp-edges;
}

*,
*:after,
*:before {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  position: relative;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  background-color: var(--color-black);
  color: var(--font-color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  outline: none;
}

button:disabled {
  cursor: not-allowed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus-visible {
  outline: 1px solid rgba(243, 227, 122, 1);
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.increase-decrease-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 23px;
  height: 23px;
  background-color: #222222;
  border: 2px solid var(--color-black);
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

.increase-decrease-btn:after {
  position: absolute;
  top: -3px;
  left: -3px;
  content: '';
  width: 25px;
  height: 25px;
  border: 1px solid #222222;
  border-radius: 50%;
}

.increase-decrease-btn:disabled {
  color: rgba(64, 65, 65, 1);
}

.simplebar-scrollbar:before {
  background: white;
}

/*Toast Styles Overrides*/

.Toastify__toast {
  position: relative;
  border-radius: 0;
  min-width: 484px;
}

.Toastify__toast:before {
  position: absolute;
  width: 4px;
  height: 50%;
  content: '';
  top: 0;
  left: 0;
  background-color: #c3c4c3;
}

.Toastify__toast:after {
  position: absolute;
  width: 4px;
  height: 50%;
  content: '';
  top: 50%;
  left: 0;
  background: linear-gradient(0deg, #7ce3cb 0%, #7963b2 135%);
}

.Toastify__toast-body {
  padding: 14px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Toastify__toast-container--top-right {
  top: 122px;
  right: 51px;
}

/*React transition group*/

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.popup-overlay {
  background-color: rgba(3, 3, 3, 0.8);
}
